<template>
    <div class="">
        <div class="vx-row justify-center">
            <h2>{{$t('momoPayment.informationPay')}}</h2>
        </div>
        <div class="vx-row lg:w-1/2 w-full mx-auto mt-4" v-if="momoData">
            <vx-card>
                <div class="flex justify-between">
                    <span class="font-semibold">{{$t('momoPayment.status')}}</span>
                    <H4 :class="classMessage">
                        {{ momoData.message }}</H4>
                </div>

                <vs-divider/>

                <div class="flex justify-between mb-2">
                    <span class="font-semibold">{{$t('momoPayment.orderId')}}</span>
                    <span>{{ momoData.orderId }}</span>
                </div>
                <div class="flex justify-between mb-2">
                    <span class="font-semibold">{{$t('momoPayment.orderInfo')}}</span>
                    <span>{{ momoData.orderInfo }}</span>
                </div>
                <div class="flex justify-between mb-2">
                    <span class="font-semibold">{{$t('momoPayment.orderType')}}</span>
                    <span>{{ momoData.orderType }}</span>
                </div>
                <div class="flex justify-between mb-2">
                    <span class="font-semibold">{{$t('momoPayment.payType')}}</span>
                    <span>{{ momoData.payType }}</span>
                </div>
                <div class="flex justify-between mb-2">
                    <span class="font-semibold">{{$t('momoPayment.responseTime')}}</span>
                    <span>{{ momoData.responseTime }}</span>
                </div>

                <vs-divider/>

                <div class="flex justify-between mb-2">
                    <span class="font-semibold">{{$t('momoPayment.amount')}}</span>
                    <H3 class="text-success">{{ formatPrice(momoData.amount) }} đ</H3>
                </div>

                <div class="flex justify-between mt-5">
                    <vs-button class="w-full ml-2" @click="$router.push('/user/manage-camera-layout')">{{$t('momoPayment.home')}}</vs-button>
                </div>
            </vx-card>
        </div>

    </div>

</template>

<script>
import moment from "moment";

export default {
    data() {
        return {
            momoData: null,
            classMessage: `font-medium cursor-pointer `
        }
    },
    created() {
        let dataQuery = null;
        dataQuery = this.$route.query;
        this.$vs.loading();
        let url = '/payment/momo/redirect-url?';
        this.$crm.get(url + new URLSearchParams(dataQuery).toString()).then((response) => {
            let classMess = response.data && dataQuery.resultCode == 0 ? 'text-success' : 'text-danger';
            this.classMessage = this.classMessage + classMess;
            dataQuery.message = response.data && dataQuery.resultCode == 0 ? this.$t('momoPayment.successful') : this.$t('momoPayment.failure');
            dataQuery.responseTime = moment(new Date(Number.parseFloat(dataQuery.responseTime))).format("HH:mm:ss DD/MM/YYYY");
            this.momoData = dataQuery;
            this.$vs.loading.close();
        }).catch((err) => {
            this.$vs.loading.close();
            return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
        });
    },
    methods: {
        formatPrice(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    }
}
</script>

<style scoped>
.title {
    font-size: medium;
    font-weight: bold;
}

.card {
    border: 2px;
}
</style>
